import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      //translation file path
      loadPath: "/assets/i18n/{{ns}}/{{lng}}.json",
    },
    fallback: "en",
    //should be disabled in production
    debug: false,
    //you can have multiple name spaces
    //in case you want to divide huge translation into smalled pieces.
    ns: [
      "navbar",
      "footer",
      "home",
      "playground",
      "classroom",
      "common",
      "login",
      "signup",
    ],

    direction: {
      en: "ltr", // Left-to-right for English
      ar: "rtl", // Right-to-left for Arabic
      // Define direction for more languages...
    },

    interpolation: {
      espaceValue: false,
      formatSeparator: ",",
    },
  });

// Add error handling after the init
i18n.on("error", function (err) {
  console.error("i18n error:", err);
});

export default i18n;