import emojiFlags from "emoji-flags";
import countries from "i18n-iso-countries";
import ar from "i18n-iso-countries/langs/ar.json";
import { getCountryCallingCode } from "libphonenumber-js";
import { getCodeList } from "country-list";
import { convertToIndianNumerals } from "./utilities";
countries.registerLocale(ar);

export const TOKEN_NAME = "ediscoolT";


const allCountryCodes = Object.keys(getCodeList()).map((code) =>
  code.toUpperCase()
);





export const countries_en = allCountryCodes
  .map((code) => {
    try {
      const callingCode = getCountryCallingCode(code);
      return {
        value: code,
        label: `${emojiFlags.countryCode(code).emoji} ${
          emojiFlags.countryCode(code).name
        } (+${callingCode})`,
      };
    } catch (error) {
      //console.error(`Could not get calling code for country: ${code}`);
      return undefined;
    }
  })
  .filter(Boolean);


export const countries_ar = allCountryCodes
  .map((code) => {
    try {
      const callingCode = getCountryCallingCode(code);
      const indianCallingCode = convertToIndianNumerals(callingCode);
      const countryNameInArabic = countries.getName(code, "ar");
      return {
        value: code,
        label: `${
          emojiFlags.countryCode(code).emoji
        } ${countryNameInArabic} (+${indianCallingCode})`,
      };
    } catch (error) {
      //console.error(`Could not get calling code for country: ${code}`);
      return undefined;
    }
  })
  .filter(Boolean);


export const languages = [
  { value: "ar", label: emojiFlags.countryCode("OM").emoji + " - ع" },
  { value: "en", label: emojiFlags.countryCode("US").emoji + " - Eng." },
];


export const classes_en = [
  { value: "0", label: "Nursery" },
  { value: "1", label: "year 1" },
  { value: "2", label: "year 2" },
  { value: "3", label: "year 3" },
  { value: "4", label: "year 4" },
  { value: "5", label: "year 5" },
  { value: "6", label: "year 6" },
  { value: "7", label: "year 7" },
  { value: "8", label: "year 8" },
  { value: "9", label: "year 9" },
  { value: "10", label: "year 10" },
  { value: "11", label: "year 11" },
  { value: "12", label: "year 12" },
  { value: "13", label: "Graduate" },
];

export const classes_ar = [
  { value: "0", label: "روضة" },
  { value: "1", label: "الصف الأول" },
  { value: "2", label: "الصف الثاني" },
  { value: "3", label: "الصف الثالث" },
  { value: "4", label: "الصف الرابع" },
  { value: "5", label: "الصف الخامس" },
  { value: "6", label: "الصف السادس" },
  { value: "7", label: "الصف السابع" },
  { value: "8", label: "الصف الثامن" },
  { value: "9", label: "الصف التاسع" },
  { value: "10", label: "الصف العاشر" },
  { value: "11", label: "الصف الحادي عشر" },
  { value: "12", label: "الصف الثاني عشر" },
  { value: "13", label: "متخرج" },
];