import React, { useRef, useState, useEffect } from "react";
import dayjs from "dayjs";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import {
  getCountryCallingCode,
  parsePhoneNumberFromString,
} from "libphonenumber-js";
import ReCAPTCHA from "react-google-recaptcha";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
//import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import MyButton from "../components/MyButton";
import MySpinner from "../components/MySpinner";
import MyAutoComplete from "../components/common/MyAutoComplete";
import ModalInfo from "../components/modals/ModalInfo";
import ModalAlert from "../components/modals/ModalAlert";
import {
  checkNumberValidity,
  checkPhoneNumberValidity,
  checkEmailValidity,
  checkNameValidity,
  checkPersonNameValidity,
  containsIndianNumerals,
  convertToArabicNumerals,
  checkPasswordComplexity,
  hashPassword,
} from "../commons/utilities";

import {
  countries_ar,
  countries_en,
  classes_ar,
  classes_en,
} from "../commons/constants";
import { verifyCaptchaAPI, userSignUpRequestAPI } from "../api/api";
import { useAppearanceContext } from "../context/AppearanceContext";
import classes from "./page.module.css";
import signUpClasses from "./signUp.module.css";

function SignUp() {
  const { t, i18n } = useTranslation(["signup"]);
  const { isMobile, isSmallerScreen } = useAppearanceContext();

  const [showModalAlert, setShowModalAlert] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState("An Error!");
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [modalInfoMessage, setModalInfoMessage] = useState("Info Message!");

  const [isBusy, setIsBusy] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [isInvalidFirstName, setIsInvalidFirstName] = useState(false);
  const [lastName, setLastName] = useState("");
  const [isInvalidLastName, setIsInvalidLastName] = useState(false);
  const [userName, setUserName] = useState("");
  const [isInvalidUserName, setIsInvalidUserName] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [isInvalidClass, setIsInvalidClass] = useState(false);
  const [invalidClassMessage, setInvalidClassMessage] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [isInvalidDateOfBirth, setIsInvalidDateOfBirth] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validFullPhoneNumber, setValidFullPhoneNumber] = useState("");
  const [isInvalidPhoneNum, setIsInvalidPhoneNum] = useState(false);
  const [email, setEmail] = useState("");
  const [remail, setREmail] = useState("");
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isInvalidREmail, setIsInvalidREmail] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isInvalidCountry, setIsInvalidCountry] = useState(false);
  const [invalidCountryMessage, setInvalidCountryMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRPassword, setShowRPassword] = useState(false);
  const [password, setPassword] = useState(null);
  const [rpassword, setRPassword] = useState(null);
  const [isInvalidPassword, setIsInvalidPassword] = useState(false);
  const [isInvalidRPassword, setIsInvalidRPassword] = useState(false);

  const [signUpRequestResponse, setSignUpRequestResponse] = useState(null);

  const [captachVerificationResponse, setCaptchaVerificationResponse] =
    useState(null);

  const recaptchaRef = useRef();

  useEffect(() => {
    if (captachVerificationResponse) {
      setIsBusy(false);
      if (captachVerificationResponse.success) {
        console.log("reCAPTCHA successfully verified.");
        submitSignUpRequest();
      } else {
        console.log("reCAPTCHA verification failed.");
        setModalAlertMessage(
          "reCAPTCHA verification failed. Please try again."
        );
        setShowModalAlert(true);
      }
      setCaptchaVerificationResponse(null);
    }
  }, [captachVerificationResponse]);

  //check if sign up request response is received
  useEffect(() => {
    if (signUpRequestResponse) {
      console.log(`>>> signUpRequestResponse is received <<<<`);
      if (signUpRequestResponse.success) {
        console.log("signUpRequestResponse is valid.");
        let message = `Thank you ${signUpRequestResponse.username}. Your request has been submitted successfully. 
                        You will shortly receive an email on (${signUpRequestResponse.email}) for verification.`;
        setModalInfoMessage(message);
        setShowModalInfo(true);
      } else {
        console.log("Error in sign up request from backend >> do nothing");
        let message = `Unfortunately, your request has not been submitted successfully. Error: ${
          signUpRequestResponse.errorMessage || "undefined"
        }. Please try again. `;
        setModalAlertMessage(message);
        setShowModalAlert(true);
      }
      setSignUpRequestResponse(null);
      setIsBusy(false);
    }
  }, [signUpRequestResponse]);

  const handleFirstName = (e) => {
    if (checkNameValidity(e.target.value) || e.target.value === "") {
      setFirstName(e.target.value);
      setIsInvalidFirstName(false);
    } else {
      console.error("first name is invalid.");
      setIsInvalidFirstName(true);
    }
  };

  const handleLastName = (e) => {
    if (checkNameValidity(e.target.value) || e.target.value === "") {
      setLastName(e.target.value);
      setIsInvalidLastName(false);
    } else {
      console.error("last name is invalid.");
      setIsInvalidLastName(true);
    }
  };

  const handleUserName = (e) => {
    if (checkPersonNameValidity(e.target.value) || e.target.value === "") {
      setUserName(e.target.value);
      setIsInvalidUserName(false);
    } else {
      console.error("user name is invalid.");
      setIsInvalidUserName(true);
    }
  };

  const handleAccountPhoneNumber = (e) => {
    console.log(
      `phone num: ${e.target.value}, country code: ${
        selectedCountry
          ? getCountryCallingCode(selectedCountry.value)
          : "undefined"
      }`
    );
    if (checkPhoneNumberValidity(e.target.value)) {
      setPhoneNumber(e.target.value);
      setIsInvalidPhoneNum(false);
    } else {
      console.error("phone num. is invalid.");
      setIsInvalidPhoneNum(true);
    }
  };

  const handleEmailInput = (e) => {
    if (checkEmailValidity(e.target.value)) {
      setEmail(e.target.value);
      setIsInvalidEmail(false);
    } else {
      setEmail(e.target.value);
      console.error("email is invalid.");
      setIsInvalidEmail(true);
    }
  };

  const handleREmailInput = (e) => {
    if (checkEmailValidity(e.target.value)) {
      setREmail(e.target.value);
      setIsInvalidREmail(false);
    } else {
      setREmail(e.target.value);
      console.error("email is invalid.");
      setIsInvalidREmail(true);
    }
  };

  const verifySignUpRequest = (e) => {
    e.preventDefault();

    //1- check all inputs are not empty
    if (
      !firstName ||
      !lastName ||
      !userName ||
      !selectedClass ||
      !dateOfBirth ||
      !selectedCountry ||
      !phoneNumber ||
      !email ||
      !remail ||
      !password ||
      !rpassword
    ) {
      if (!firstName) setIsInvalidFirstName(true);
      if (!lastName) setIsInvalidLastName(true);
      if (!userName) setIsInvalidUserName(true);
      if (!selectedClass) {
        setIsInvalidClass(true);
        setInvalidClassMessage(t("helperMessageClass"));
      }
      if (!dateOfBirth) setIsInvalidDateOfBirth(true);
      if (!selectedCountry) {
        setIsInvalidCountry(true);
        setInvalidCountryMessage(t("helperMessageCountry"));
      }
      if (!phoneNumber) setIsInvalidPhoneNum(true);
      if (!email) setIsInvalidEmail(true);
      if (!remail) setIsInvalidREmail(true);
      if (!password) setIsInvalidPassword(true);
      if (!rpassword) setIsInvalidRPassword(true);

      let message = `Please fill in all required fields.`;
      setModalAlertMessage(message);
      setShowModalAlert(true);
      return;
    }

    //2- check password and re-password match
    if (password !== rpassword) {
      setModalAlertMessage("Passwords do not match.");
      setShowModalAlert(true);
      setIsInvalidPassword(true);
      setIsInvalidRPassword(true);
      return;
    }

    //3- check password complexity
    if (!checkPasswordComplexity(password)) {
      setModalAlertMessage(
        "Password must contain at least 8 characters, including uppercase, lowercase, numbers and special characters."
      );
      setShowModalAlert(true);
      setIsInvalidPassword(true);
      setIsInvalidRPassword(true);
      return;
    }

    //4- check email and re-email match
    if (email !== remail) {
      setModalAlertMessage("Emails do not match.");
      setShowModalAlert(true);
      setIsInvalidEmail(true);
      setIsInvalidREmail(true);
      return;
    }

    //5- check input validity
    if (
      isInvalidFirstName ||
      isInvalidLastName ||
      isInvalidUserName ||
      isInvalidClass ||
      isInvalidDateOfBirth ||
      isInvalidPhoneNum ||
      isInvalidEmail ||
      isInvalidREmail ||
      isInvalidPassword ||
      isInvalidRPassword
    ) {
      let message = `Name, class, or phone number, or email is invalid, plz check them.`;
      setModalAlertMessage(message);
      setShowModalAlert(true);
      return;
    } else {
      //change phone number (if in hindu-arabic numerals to arabic numerals)
      let normalizedPhoneNumber;
      if (containsIndianNumerals(phoneNumber)) {
        console.log(`phone number contains indian numerals`);
        normalizedPhoneNumber = convertToArabicNumerals(phoneNumber);
        console.log(`converted phone number: ${normalizedPhoneNumber}`);
      } else {
        console.log(`phone number does not contain indian numerals`);
        normalizedPhoneNumber = phoneNumber;
      }

      let fullPhoneNumber = `+${getCountryCallingCode(
        selectedCountry.value
      )}${normalizedPhoneNumber}`;
      console.log(`full phone number: ${fullPhoneNumber}`);
      let parsedPhoneNumber = parsePhoneNumberFromString(
        fullPhoneNumber,
        selectedCountry.value
      );
      if (!parsedPhoneNumber?.isValid()) {
        let message = `Phone number is invalid.`;
        setModalAlertMessage(message);
        setShowModalAlert(true);
        return;
      }

      console.log(`valid phone number proceed...`);

      setValidFullPhoneNumber(fullPhoneNumber);

      //////////////////////

      //check if reCAPTCHA is verified
      const captchaValue = recaptchaRef.current.getValue();
      if (!captchaValue) {
        setModalAlertMessage("Please tick the checkbox of the reCAPTCHA!");
        setShowModalAlert(true);
        return;
      }

      console.log("submit reCAPTCHA to server for verification.");

      verifyCaptchaAPI(captchaValue, setCaptchaVerificationResponse);

      console.log(
        `All parameters are valid. Verify captacha and then submit sign up request...`
      );
    }
  };

  const submitSignUpRequest = () => {
    console.log(`This is submitSignUp, username is ${userName}, 
      country is ${selectedCountry.value}, phone number is ${validFullPhoneNumber}, email is ${email}`);
    const kilma = hashPassword(password);
    userSignUpRequestAPI(
      firstName,
      lastName,
      userName,
      selectedClass.value,
      dateOfBirth,
      selectedCountry.value,
      validFullPhoneNumber,
      email,
      password,
      kilma,
      setSignUpRequestResponse
    );

    setIsBusy(true);
    setFirstName("");
    setLastName("");
    setUserName("");
    setSelectedClass(null);
    setDateOfBirth(null);
    setSelectedCountry(null);
    setPhoneNumber("");
    setEmail("");
    setREmail("");
    setPassword("");
    setRPassword("");
  };

  return (
    <div className={classes.main_div}>
      <div className={classes.body_div}>
        <div className={classes.body_main_container}>
          <div className={classes.body_sub_container} dir={i18n.dir()}>
            <div className={signUpClasses.getStartedContainer}>
              <p className={classes.titleText}>{t("sign up")}</p>
              <form
                className={
                  isSmallerScreen
                    ? signUpClasses.getStartedForm_mobile
                    : signUpClasses.getStartedForm
                }
                onSubmit={verifySignUpRequest}
              >
                <div className={signUpClasses.getStartedElement}>
                  <TextField
                    type="text"
                    id="firstName"
                    label={t("first name")}
                    className={signUpClasses.input_element}
                    autoComplete="off"
                    onChange={handleFirstName}
                    value={firstName}
                    error={isInvalidFirstName}
                    helperText={
                      isInvalidFirstName ? t("invalid first name") : null
                    }
                    required
                  />
                </div>

                <div className={signUpClasses.getStartedElement}>
                  <TextField
                    type="text"
                    id="lastName"
                    label={t("last name")}
                    className={signUpClasses.input_element}
                    autoComplete="off"
                    onChange={handleLastName}
                    value={lastName}
                    error={isInvalidLastName}
                    helperText={
                      isInvalidLastName ? t("invalid last name") : null
                    }
                    required
                  />
                </div>

                <div className={signUpClasses.getStartedElement}>
                  <TextField
                    type="text"
                    id="username"
                    label={t("username")}
                    className={signUpClasses.input_element}
                    autoComplete="off"
                    onChange={handleUserName}
                    value={userName}
                    error={isInvalidUserName}
                    helperText={
                      isInvalidUserName ? t("invalid user name") : null
                    }
                    required
                  />
                </div>

                <div className={signUpClasses.getStartedElement}>
                  <MyAutoComplete
                    id="class"
                    list={i18n.language === "ar" ? classes_ar : classes_en}
                    title={t("class")}
                    setSelectedOption={(value) => {
                      setSelectedClass(value);
                      setIsInvalidClass(false);
                    }}
                    errorState={isInvalidClass}
                    errorMessage={invalidClassMessage}
                    isNull={!selectedClass}
                    required
                  />
                </div>

                <div className={signUpClasses.getStartedElement}>
                  <LocalizationProvider id="dob" dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label={t("date of birth")}
                      defaultValue={dayjs()}
                      value={dateOfBirth ? dateOfBirth : null}
                      onChange={(newValue) => {
                        console.log(`date of birth: ${newValue}`);
                        setDateOfBirth(
                          newValue ? newValue.startOf("day") : null
                        );
                      }}
                      error={isInvalidDateOfBirth}
                      disableFuture={true}
                      renderInput={(props) => (
                        <TextField
                          {...props}
                          variant="outlined"
                          error={isInvalidDateOfBirth}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>

                <div className={signUpClasses.getStartedElement}>
                  <MyAutoComplete
                    id="country"
                    list={i18n.language === "ar" ? countries_ar : countries_en}
                    title={t("country")}
                    setSelectedOption={(value) => {
                      setSelectedCountry(value);
                      setIsInvalidCountry(false);
                    }}
                    errorState={isInvalidCountry}
                    errorMessage={invalidCountryMessage}
                    isNull={!selectedCountry}
                    required
                  />
                </div>
                <div className={signUpClasses.getStartedElement}>
                  <TextField
                    type="text"
                    id="phoneNumber"
                    label={t("phone number")}
                    className={signUpClasses.input_element}
                    autoComplete="off"
                    onChange={handleAccountPhoneNumber}
                    value={phoneNumber}
                    error={isInvalidPhoneNum}
                    helperText={
                      isInvalidPhoneNum ? t("helperMessagePhoneNumber") : null
                    }
                    required
                  />
                </div>
                <div className={signUpClasses.getStartedElement}>
                  <TextField
                    type="email"
                    id="email"
                    label={t("email")}
                    className={signUpClasses.input_element}
                    autoComplete="off"
                    helperText={isInvalidEmail ? t("helperMessageEmail") : null}
                    onChange={handleEmailInput}
                    value={email}
                    error={isInvalidEmail}
                    required
                  />
                </div>
                <div className={signUpClasses.getStartedElement}>
                  <TextField
                    type="email"
                    id="email"
                    label={t("confirm email")}
                    className={signUpClasses.input_element}
                    autoComplete="off"
                    helperText={
                      isInvalidREmail ? t("helperMessageEmail") : null
                    }
                    onChange={handleREmailInput}
                    value={remail}
                    error={isInvalidREmail}
                    required
                  />
                </div>
                <div className={signUpClasses.getStartedElement}>
                  <TextField
                    className="input_element"
                    id="password"
                    label={t("password")}
                    rows={1}
                    type={showPassword ? "text" : "password"}
                    value={password ? password : ""}
                    error={isInvalidPassword}
                    onChange={(e) => {
                      setIsInvalidPassword(false);
                      setPassword(e.target.value);
                      console.log(`password: ${e.target.value}`);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div
                            onClick={() => {
                              setIsInvalidPassword(false);
                              setShowPassword(!showPassword);
                            }}
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                </div>

                <div className={signUpClasses.getStartedElement}>
                  <TextField
                    className="input_element"
                    id="r_password"
                    label={t("confirm password")}
                    rows={1}
                    type={showRPassword ? "text" : "password"}
                    value={rpassword ? rpassword : ""}
                    error={isInvalidRPassword}
                    onChange={(e) => {
                      setIsInvalidRPassword(false);
                      setRPassword(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <div onClick={() => setShowRPassword(!showRPassword)}>
                            {showRPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    required
                  />
                </div>
                <div className={classes.button_div}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                  />
                </div>
              </form>

              <div className={signUpClasses.button_div}>
                <MyButton
                  variant={"primary"}
                  onClickHandler={verifySignUpRequest}
                  title={t("sign up")}
                />
              </div>
            </div>
          </div>
        </div>

        <ModalAlert
          show={showModalAlert}
          alertMessage={modalAlertMessage}
          handleClose={() => {
            setShowModalAlert(false);
          }}
        />

        <ModalInfo
          show={showModalInfo}
          infoMessage={modalInfoMessage}
          handleClose={() => {
            setShowModalInfo(false);
          }}
        />

        {isBusy && <MySpinner />}
      </div>
    </div>
  );
}

export default SignUp;
