import React, { useState, useEffect } from "react";
import {useTranslation} from "react-i18next";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function SingleSelect({ list, initialLabel, setSelectedOption }) {
  const { t, i18n } = useTranslation();
  const [selectedOption_local, setSelectedOption_local] = useState(null);
  const [listTags, setListTags] = useState([]);

  useEffect(() => {
    const temp_tags = [];
    if (list) {
      {
        list.map((item) => {
          temp_tags.push(<MenuItem value={item.value}>{item.label}</MenuItem>);
        });
      }
    }
    setListTags(temp_tags);
  }, [list]);

  useEffect(() => {
    if (list && initialLabel) {
      const itemWithLabel = list.find((item) => item.label === initialLabel);
      if (itemWithLabel) {
        setSelectedOption_local(itemWithLabel.value);
        setSelectedOption(itemWithLabel.value);
      }
    }
  }, []);

  const handleChange = (event) => {
    console.log(`)))))Chosen option is: ${event.target.value}.`);
    setSelectedOption_local(event.target.value);
    setSelectedOption(event.target.value);
  };

  return (
    <Box>
      <FormControl fullWidth lang={i18n.language}>
        <Select
          labelId="single-select-label"
          id="single-select"
          value={selectedOption_local}
          onChange={handleChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: "10em", // This height will show around 5 items.
                overflow: "auto", // Enable scrolling
                backgroundColor: "rgba(255, 255, 255, 0.8)", // Set the background color with transparency
              },
            },
          }}
          lang={i18n.language}
        >
          {listTags}
        </Select>
      </FormControl>
    </Box>
  );
}

export default SingleSelect;