import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeroSection from "../components/homeComponents/HeroSection";
import Features from "../components/homeComponents/Features";
import classes from "./page.module.css";

function Home() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["home"]);

  useEffect(() => {
    console.log(`Mode of operation: ${process.env.REACT_APP_MODE}`);
    console.log(`Compilation time: ${process.env.REACT_APP_COMPILATION_TIME}`);
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.substring(1); // Remove the '#' from the hash
      const section = document.getElementById(sectionId);
      if (section) {
        console.log(`Scrolling to section: ${sectionId}`);
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className={classes.main_div}>
      <div className={classes.body_div}>
        <div className={classes.body_main_container}>
          <div className={classes.body_sub_container}>
            <HeroSection />
          </div>
          <div className={classes.body_sub_container}>
            <span className={classes.sub_container_title}>{t("Why")}</span>
            <Features />
          </div>
          <div className={classes.body_sub_container} id="getStarted"  dir={i18n.dir()}>
            {t("Easy to sign up")}{" "}
            <span
              className={classes.importantTextPointer}
              onClick={() => navigate("/signup")}
            >
              {t("sign up now")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
