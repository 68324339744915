import independentImage from "./swiperImages/independent.jpg";
import parentsImage from "./swiperImages/parents.jpg";
import loveImage from "./swiperImages/loveLearning.jpg";

export const images = [
  {
    title: "Students, parents, and teachers",
    title_ar: "الطلاب والآباء والمعلمين",
    text: "EdIsCool is a platform that helps students, parents, and teachers. It is a fun and interactive way to learn and teach.",
    text_ar: "EdIsCool هي منصة تساعد الطلاب والآباء والمعلمين. إنها طريقة ممتعة وتفاعلية للتعلم والتدريس.",
    index: 0,
    image: parentsImage,
  },
  {
    title: "Continuous learning.",
    title_ar: "التعلم المستمر",
    text: "EdIsCool is for those who continuously seek knowledge and love learning.",
    text_ar: "EdIsCool هو لأولئك الذين يسعون باستمرار للمعرفة ويحبون التعلم.",
    index: 1,
    image: loveImage,
  },
  {
    title: "Independence",
    title_ar: "الاستقلالية",
    text: "EdIsCool helps students to be independent and self-reliant.",
    text_ar: "تساعد EdIsCool الطلاب على أن يكونوا مستقلين ويعتمدون على أنفسهم.",
    index: 3,
    image: independentImage,
  },
];
