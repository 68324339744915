import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import MyButton from "../MyButton";
import classes from "../../pages/page.module.css";
import settingsFormClasses from "./settingsForm.module.css";

export default function ChangeUserNameForm({ username, handleUsernameChange }) {
  const [newUserName, setNewUserName] = useState("");

  const localHandlingOfNameChange = (e) => {
    e.preventDefault();
    handleUsernameChange(newUserName);
    setNewUserName("");
  };

  return (
    <div className={settingsFormClasses.settings_form_container}>
      <p className={classes.titleText}>Change username?</p>
      <form className={settingsFormClasses.settings_form} onSubmit={localHandlingOfNameChange}>
      <div className={settingsFormClasses.settings_element}>
          <TextField
            type="text"
            id="username"
            className={settingsFormClasses.settings_imput_element}
            readonly
            label="User Name"
            //onChange={(e) => setUser(e.target.value)}
            value={username || ""}
          />
        </div>
        <div className={settingsFormClasses.settings_element}>
          <TextField
            type="text"
            id="newUserName"
            className={settingsFormClasses.settings_imput_element}
            label="New User Name"
            onChange={(e) => setNewUserName(e.target.value)}
            value={newUserName || ""}
          />
        </div>
        <div className={settingsFormClasses.settings_button_div}>
          <MyButton
            variant={"primary"}
            onClickHandler={localHandlingOfNameChange}
            title={"Change User Name"}
          />
        </div>
      </form>
    </div>
  );
}
