import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import classes from "./page.module.css";

function About() {
  const { t, i18n } = useTranslation(["common"]);

  return (
    <div className={classes.main_div}>
      <div className={classes.body_div}>
        <div className={classes.body_main_container}>
          <div className={classes.body_sub_container} dir={i18n.dir()}>
            <p>{t("page is under construction")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
