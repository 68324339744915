import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
//import { Navigate, useLocation } from "react-router-dom";
import Home from "./pages/Home";
//import BBBHandles from "./pages/BBBHandles";
//import MainSections from "./pages/MainSections";
import HealthCheck from "./pages/HealthCheck";
import Playground from "./pages/Playground";
import LMS from "./pages/LMS";
import Course from "./pages/Course";
import Settings from "./pages/Settings";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import VerifyEmail from "./pages/VerifyEmail";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import RegisterationHandler from "./pages/RegisterationHandler";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import ProtectedAdminRoute from "./protectedRoutes/ProtectedAdminRoutes";
import ProtectedUserRoute from "./protectedRoutes/ProtectedUserRoute";
//import LoginTest from "./pages/LoginTest";

function App() {
  return (
    <Router>
      <div className="app_container">
        <Navbar />
        {/*<MainSections />*/}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/lms" element={<LMS />} />
          <Route path="/lms/:courseName" element={<Course />} />
          <Route
            path="/settings"
            element={
              <ProtectedUserRoute>
                <Settings />
              </ProtectedUserRoute>
            }
          />
          <Route path="/playground" element={<Playground />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/verifyemail" element={<VerifyEmail />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/about" element={<About />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route
            path="/registeration"
            element={
              <ProtectedAdminRoute>
                <RegisterationHandler />
              </ProtectedAdminRoute>
            }
          />
          <Route
            path="/healthcheck"
            element={
              <ProtectedAdminRoute>
                <HealthCheck />
              </ProtectedAdminRoute>
            }
          />
          {/*<Route path="/logintest" element={<LoginTest />} />*/}
          {/*<Route path="/bbb" element={<BBBHandles />} />*/}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
