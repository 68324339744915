import React, { useState, useEffect } from "react";
import { useRegisterationContext } from "../context/RegisterationContext";
import ModalAlert from "../components/modals/ModalAlert";
import ModalInfo from "../components/modals/ModalInfo";
import ModalRejection from "../components/modals/ModalRejection";
import MyButton from "../components/MyButton";
import MySpinner from "../components/MySpinner";
import RegRequestCard from "../components/RegRequestCard";
import {
  acceptEnrollmentRequestAPI,
  rejectEnrollmentRequestAPI,
} from "../api/api";
import classes from "./page.module.css";
import { Modal } from "@mui/material";

function RegisterationHandler() {
  const { regRequests, removeRegRequest } = useRegisterationContext();

  const [acceptEnrollmentResponse, setAcceptEnrollmentResponse] =
    useState(null);
  const [rejectEnrollmentResponse, setRejectEnrollmentResponse] =
    useState(null);
  const [isBusy, setIsBusy] = useState(false);

  const [showModalInfo, setShowModalInfo] = useState(false);
  const [modalInfoMessage, setModalInfoMessage] = useState("Info Message!");
  const [showModalAlert, setShowModalAlert] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState("An Error!");

  //reload component whenever regRequests changes
  useEffect(() => {
    console.log(
      `RegisterationHandler: regRequests: ${JSON.stringify(regRequests)}`
    );
  }, [regRequests]);

  //check if acceptEnrollmentResponse is not null
  useEffect(() => {
    if (acceptEnrollmentResponse) {
      if (acceptEnrollmentResponse.success) {
        //delete the regRequest from regRequests
        removeRegRequest(acceptEnrollmentResponse.regRequestId);
        setModalInfoMessage("Accept Enrollment Request is successful!");
        setShowModalInfo(true);
      } else {
        console.log(
          `Accept Enrollment Request is failed!, errorMessage: ${acceptEnrollmentResponse.errorMessage}`
        );
        setModalAlertMessage("Accept Enrollment Request is failed!");
        setShowModalAlert(true);
      }
      setIsBusy(false);
      setAcceptEnrollmentResponse(null);
    }
  }, [acceptEnrollmentResponse]);

  //check if rejectEnrollmentResponse is not null
  useEffect(() => {
    if (rejectEnrollmentResponse) {
      if (rejectEnrollmentResponse.success) {
        //delete the regRequest from regRequests
        removeRegRequest(rejectEnrollmentResponse.regRequestId);
        setModalInfoMessage("Reject Enrollment Request is successful!");
        setShowModalInfo(true);
      } else {
        setModalAlertMessage("Reject Enrollment Request is failed!");
        setShowModalAlert(true);
      }
      setIsBusy(false);
      setRejectEnrollmentResponse(null);
    }
  }, [rejectEnrollmentResponse]);

  //handle accept enrollment button click
  const handleAccept = (regRequest) => {
    console.log("handleAccept: ", regRequest);

    setAcceptEnrollmentResponse(null);
    setIsBusy(true);
    acceptEnrollmentRequestAPI(
      regRequest._id,
      regRequest.userId,
      regRequest.courseId,
      setAcceptEnrollmentResponse
    );
  };

  //handle reject enrollment button click
  //const handleReject = (regRequest) => {

  //};

  const handleReject = (regRequest, note) => {
    console.log(`processReject: ${regRequest}, and note: ${note}`);
    console.log("Continue here...");

    setRejectEnrollmentResponse(null);
    setIsBusy(true);
    rejectEnrollmentRequestAPI(
      regRequest._id,
      regRequest.userId,
      regRequest.courseId,
      note,
      setRejectEnrollmentResponse
    );
  };

  return (
    <div className={classes.main_div}>
      <div className={classes.body_div}>
        <div className={classes.body_main_container}>
          <div className={classes.body_sub_container}>
            {regRequests && regRequests.length ? (
              <>
                {regRequests.map((regRequest, index) => (
                  <RegRequestCard
                    key={index}
                    regRequest={regRequest}
                    handleAccept={handleAccept}
                    handleReject={handleReject}
                  />
                ))}
              </>
            ) : (
              <p>
                No registeration requests. Refresh to check for newer request.
              </p>
            )}
          </div>
        </div>
      </div>
      <ModalInfo
        show={showModalInfo}
        infoMessage={modalInfoMessage}
        handleClose={setShowModalInfo}
      />
      <ModalAlert
        show={showModalAlert}
        alertMessage={modalAlertMessage}
        handleClose={setShowModalAlert}
      />
      {isBusy && <MySpinner message={"Wait..."} />}
    </div>
  );
}

export default RegisterationHandler;
