import React from "react";
import { useTranslation } from "react-i18next";
import Card from "../card/Card";
import multipleCardClasses from "../multipleCard.module.css";
import { features_list } from "./FeaturesData";

function Features() {
  const { t, i18n } = useTranslation(["home"]);
  return (
    <div className={multipleCardClasses.multiple_card_container}>
      <div className={multipleCardClasses.multiple_card_container_wrapper}>
        {features_list.map((feature) => (
          <Card
            title={i18n.language === "ar" ? feature.title_ar : feature.title}
            text={i18n.language === "ar" ? feature.text_ar : feature.text}
            imageSrc={feature.imageSrc}
            imageAlt={`Image of ${feature.title}`}
            variant="no_url"
            url=""
            key={feature.index}
          />
        ))}
      </div>
    </div>
  );
}

export default Features;
