
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pagination, EffectFade, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/bundle";


import { images } from "./SwiperData";
import heroClasses from "./heroSection.module.css";

const HeroSection = () => {
  const { t, i18n } = useTranslation(["home"]);
  /*useEffect(() => {
    console.log(`number of images: ${images.length}`);
  }, [images]);*/

  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
      }}
      effect={"fade"}
      loop={true}
      modules={[Pagination, EffectFade, Autoplay]}
      className={heroClasses.mySwiper}
    >
      {images.map((image) => (
        <SwiperSlide key={image.index} className={heroClasses.mySwiperSlide}>
          <div
            className={
              image.index % 2 === 0
                ? heroClasses.overlayTextRight
                : heroClasses.overlayTextLeft
            }
            dir={i18n.dir()}
          >
            <p className={heroClasses.title} dir={i18n.dir()}>
              {i18n.language === "ar" ? image.title_ar : image.title}
            </p>
            <p className={heroClasses.text} dir={i18n.dir()}>
              {i18n.language === "ar" ? image.text_ar : image.text}
            </p>
          </div>
          <img
            key={image.index}
            src={image.image}
            alt={`Soorah index: ${image.index}`}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HeroSection;
