import React from "react";
import TextField from "@mui/material/TextField";
import classes from "../../pages/page.module.css";
import settingsFormClasses from "./settingsForm.module.css";

function PersonalInfo({ username, email, role }) {
  return (
    <div className={settingsFormClasses.settings_form_container}>
      <p className={classes.titleText}>Personal Info</p>
      <form className={settingsFormClasses.settings_form}>
        <div className={settingsFormClasses.settings_element}>
          <TextField
            type="text"
            id="first_name"
            className={settingsFormClasses.settings_imput_element}
            readonly
            label="First Name"
            value={"first name"}
          />
        </div>
        <div className={settingsFormClasses.settings_element}>
          <TextField
            type="text"
            id="last_name"
            className={settingsFormClasses.settings_imput_element}
            readonly
            label="Last Name"
            value={"last name"}
          />
        </div>
        <div className={settingsFormClasses.settings_element}>
          <TextField
            type="text"
            id="username"
            className={settingsFormClasses.settings_imput_element}
            readonly
            label="User Name"
            value={username || ""}
          />
        </div>
        <div className={settingsFormClasses.settings_element}>
          <TextField
            type="text"
            id="email"
            className={settingsFormClasses.settings_imput_element}
            readonly
            label="Email"
            value={email || ""}
          />
        </div>
        <div className={settingsFormClasses.settings_element}>
          <TextField
            type="text"
            id="role"
            className={settingsFormClasses.settings_imput_element}
            readonly
            label="Role"
            value={role || ""}
          />
        </div>
      </form>
    </div>
  );
}

export default PersonalInfo;
