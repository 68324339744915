import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CryptoJS from "crypto-js";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useAuthenticationContext } from "../context/AuthenticationContext";
import { userLoginAPI } from "../api/api";
import classes from "./page.module.css";
import "./login.css";

import MyButton from "../components/MyButton";
import MySpinner from "../components/MySpinner";

import ModalInfo from "../components/modals/ModalInfo";
import ModalAlert from "../components/modals/ModalAlert";
import { use } from "i18next";
import { checkEmailValidity } from "../commons/utilities";

function Login() {
  //const { userRef } = useRef("");
  const { t, i18n } = useTranslation(["login"]);
  const navigate = useNavigate();

  const { authenticationStatus, updateAuthenticationStatus } =
    useAuthenticationContext();

  const [email, setEmail] = useState("");
  const [kilma, setKilma] = useState("");
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isInvalidKilma, setIsInvalidKilma] = useState(false);
  const [showKilma, setShowKilma] = useState(false);
  const [loginResponse, setLoginResponse] = useState(null);
  const [loggedIn, setLoggedIn] = useState(authenticationStatus.authenticated);

  const [showModalAlert, setShowModalAlert] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState("An Error!");

  //const [showModalInfo, setShowModalInfo] = useState(false);
  //const [modalInfoMessage, setModalInfoMessage] = useState("Info Message!");
  const [isBusy, setIsBusy] = useState(false);

  //reload component when authentication status changes.
  useEffect(() => {
    console.log(
      `Login: authenticationStatus: ${JSON.stringify(authenticationStatus)}`
    );
    setLoggedIn(authenticationStatus.authenticated);
  }, [authenticationStatus.authenticated]);

  /*useEffect(() => {
    if (addNewUser) {
      const username = "ahmed";
      const password = "ahmed@1234";
      const email = "ahmed12345@gmail.com";
      const role = "student";
      const kilma = hashKilma(password);
      UserSignUpAPI(username, email, role, kilma, setSignUpResponse);
      setIsBusy(true);
    }
  }, []);*/

  useEffect(() => {
    if (loginResponse) {
      setIsBusy(false);
      console.log(
        `Got a new response for logging-in a user: ${JSON.stringify(
          loginResponse
        )}`
      );
      if (loginResponse.success) {
        console.log(`Logging-in for ${loginResponse.userName} is successful!!`);
        setLoggedIn(true);
        updateAuthenticationStatus({
          authenticated: true,
          username: loginResponse.userName,
          email: loginResponse.email,
          userId: loginResponse.userId,
          role: loginResponse.role,
          errorMessage: null,
        });

        console.log("about to navigate to home page");
        navigate("/");
      } else {
        let message = `Log-in failed`;
        setModalAlertMessage(message);
        setShowModalAlert(true);
      }
    }
  }, [loginResponse]);

  const hashKilma = (kilma) => {
    const hash = CryptoJS.SHA256(kilma);
    return hash.toString(CryptoJS.enc.Hex);
  };

  /*const handleEmailInput = (e) => {
    if (checkEmailValidity(e.target.value)) {
      setEmail(e.target.value);
      setIsInvalidEmail(false);
    } else {
      setEmail(e.target.value);
      console.error("email is invalid.");
      setIsInvalidEmail(true);
    }
  };*/

  const handleSubmitLogIn = async (e) => {
    e.preventDefault();
    setLoggedIn(false);

    //validate email
    if (!email || email.trim().length === 0 || !checkEmailValidity(email)) {
      setIsInvalidEmail(true);
      return;
    }

    let trimmedEmail = email.trim(); //remove whitespace before and after email
    try {
      const hashedKilma = hashKilma(kilma);
      userLoginAPI(trimmedEmail, hashedKilma, setLoginResponse);
      setIsBusy(true);
    } catch (error) {
      let message = `Error in hashing the kilma: (${error}).`;
      setModalAlertMessage(message);
      setShowModalAlert(true);
    }

    setEmail("");
    setKilma("");
  };

  const closeAlertModel = () => {
    setShowModalAlert(false);
  };

  return (
    <div className={classes.main_div}>
      <div className={classes.body_div}>
        <div className={classes.body_main_container}>
          <div className={classes.body_sub_container} dir={i18n.dir()}>
            {loggedIn ? (
              <div className="login_container" dir={i18n.dir()}>
                {t("already logged in")}
              </div>
            ) : (
              <div className="login_container" dir={i18n.dir()}>
                <p className={classes.titleText} dir={i18n.dir()}>
                  {t("login")}
                </p>
                <form className="login_form" onSubmit={handleSubmitLogIn}>
                  <div className="login_element">
                    <TextField
                      type="text"
                      id="email"
                      className="input_element"
                      label={t("email")}
                      autoComplete="off"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email || ""}
                      error={isInvalidEmail}
                      helperText={isInvalidEmail ? "invalid email" : null}
                      required
                    />
                  </div>
                  <div className="login_element">
                    <TextField
                      id="kilma"
                      label={t("password")}
                      rows={1}
                      type={showKilma ? "text" : "password"}
                      value={kilma ? kilma : ""}
                      error={isInvalidKilma}
                      onChange={(e) => setKilma(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <div
                              onClick={() => {
                                setShowKilma(!showKilma);
                              }}
                            >
                              {showKilma ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </div>
                          </InputAdornment>
                        ),
                      }}
                      required
                    />
                  </div>
                  <div className="button_div">
                    <MyButton
                      variant={"primary"}
                      onClickHandler={handleSubmitLogIn}
                      title={t("login")}
                    />
                  </div>

                  <div className="button_div">
                    <a
                      className={classes.importantTextPointerSmallerFont}
                      href="/forgotPassword"
                    >
                      {t("forgot password")}
                    </a>
                    <div>
                      <p> {/* empty space for styling*/}</p>
                    </div>
                    <span className={classes.textSmallerFont}>
                      {t("dont have an account")}{" "}
                      <span
                        className={classes.importantTextPointerSmallerFont}
                        onClick={() => navigate("/signup")}
                      >
                        {t("signup here")}
                      </span>
                    </span>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
        {showModalAlert && (
          <ModalAlert
            show={showModalAlert}
            alertMessage={modalAlertMessage}
            handleClose={closeAlertModel}
          />
        )}

        {isBusy && <MySpinner />}
      </div>
    </div>
  );
}

export default Login;
