import React, { useEffect, useState } from "react";
import { useAuthenticationContext } from "../context/AuthenticationContext";
import { useAppearanceContext } from "../context/AppearanceContext";
import PersonalInfo from "../components/settingsComponents/PersonalInfo";
import ChangeUserNameForm from "../components/settingsComponents/ChangeUserNameForm";
import ChangeOtherSettingsForm from "../components/settingsComponents/ChangeOtherSettingsForm";
import MyButton from "../components/MyButton";
import MySpinner from "../components/MySpinner";
import ModalInfo from "../components/modals/ModalInfo";
import ModalAlert from "../components/modals/ModalAlert";
import { changeUserNameAPI } from "../api/api";
import classes from "./page.module.css";
import settingsClasses from "./settings.module.css";

function Settings() {
  const { authenticationStatus } = useAuthenticationContext();
  const { isMobile, isSmallerScreen } = useAppearanceContext();

  const [showModalAlert, setShowModalAlert] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState("An Error!");
  const [showModalInfo, setShowModalInfo] = useState(false);
  const [modalInfoMessage, setModalInfoMessage] = useState("Info Message!");

  const [isBusy, setIsBusy] = useState(false);

  const [changeUserNameResponse, setChangeUserNameResponse] = useState(null);

  // Check if user is authenticated, if not redirect to login page.
  useEffect(() => {
    if (!authenticationStatus.authenticated) {
      console.log(
        "User is not authenticated. Redirecting from (settings) to login page."
      );
      window.location.href = "/login";
    }
  }, [authenticationStatus]);

  // check if response is received from the server for changing username
  useEffect(() => {
    if (changeUserNameResponse) {
      console.log(
        `Change-username-response received: ${JSON.stringify(
          changeUserNameResponse
        )}`
      );
      if (changeUserNameResponse.success) {
        setModalInfoMessage("Username has been changed successfully!");
        setShowModalInfo(true);
      } else {
        setModalAlertMessage(
          changeUserNameResponse.errorMessage || "An error occurred!"
        );
        setShowModalAlert(true);
      }
      setChangeUserNameResponse(null);
      setIsBusy(false);
    }
  }, [changeUserNameResponse]);

  const handleUsernameChange = (newUserName) => {
    console.log(`This is handled in the settings page, 
        username is: ${authenticationStatus.username}, and new username ${newUserName}.`);

    //remove leading and trailing whitespaces
    newUserName = newUserName.trim();

    if (
      newUserName &&
      newUserName !== "" &&
      newUserName !== " " &&
      newUserName !== authenticationStatus.username
    ) {
      setIsBusy(true);
      changeUserNameAPI(
        authenticationStatus.userId,
        newUserName,
        setChangeUserNameResponse
      );
    } else {
      setModalAlertMessage(
        "Username cannot be empty or same as the current one!"
      );
      setShowModalAlert(true);
    }
  };

  return (
    <div className={classes.main_div}>
      <div className={classes.body_div}>
        <div className={classes.body_main_container}>
          <div className={classes.body_sub_container}>
            <p className={classes.titleText}>User's Settings</p>
            <div
              defaultExpanded
              className={
                isSmallerScreen
                  ? settingsClasses.container_mobile
                  : settingsClasses.container
              }
            >
              <PersonalInfo
                username={authenticationStatus.username}
                email={authenticationStatus.email}
                role={authenticationStatus.role}
              />
              <ChangeUserNameForm
                username={authenticationStatus.username}
                handleUsernameChange={handleUsernameChange}
              />
              <ChangeOtherSettingsForm />
            </div>
          </div>
        </div>
        <ModalAlert
          show={showModalAlert}
          alertMessage={modalAlertMessage}
          handleClose={() => {
            setShowModalAlert(false);
          }}
        />

        <ModalInfo
          show={showModalInfo}
          infoMessage={modalInfoMessage}
          handleClose={() => {
            setShowModalInfo(false);
          }}
        />

        {isBusy && <MySpinner />}
      </div>
    </div>
  );
}

export default Settings;
