import fun from "./featuresImages/fun_platform.webp";
import affordable from "./featuresImages/affordable.webp";
import evaluation from "./featuresImages/evaluation.webp";
import parents from "./featuresImages/help_parents.webp";
import live from "./featuresImages/live_classroom.webp";
import uptodate from "./featuresImages/uptodate.webp";
import progress from "./featuresImages/progress.webp";

export const features_list = [
  {
    index: 0,
    title: "Fun Platform",
    title_ar: "منصة ممتعة",
    text: "Our platform is designed to be fun for kids to use. It is colorful and engaging.",
    text_ar: "صممت منصتنا لتكون ممتعة للأطفال. إنها مليئة بالألوان والجاذبية.",
    imageSrc: fun,
  },
  {
    index: 1,
    title: "Affordable",
    title_ar: "ميسورة التكلفة",
    text: "We provide quality education at an affordable price for all families.",
    text_ar: "نوفر تعليمًا عالي الجودة بأسعار معقولة لجميع الأسر.",
    imageSrc: affordable,
  },
  {
    index: 2,
    title: "Evaluation Tools",
    title_ar: "أدوات التقييم",
    text: "Our platform includes tools to evaluate students' progress and performance.",
    text_ar: "تتضمن منصتنا أدوات لتقييم الطلاب وأدائهم.",
    imageSrc: evaluation,
  },
  {
    index: 3,
    title: "Help for Parents",
    title_ar: "مساعدة للآباء",
    text: "We provide resources and support to help parents assist their children in learning.",
    text_ar: "نوفر موارد ودعمًا لمساعدة الآباء على مساعدة أطفالهم في التعلم.",
    imageSrc: parents,
  },
  {
    index: 4,
    title: "Live Classroom",
    title_ar: "الفصل الدراسي المباشر",
    text: "Interactive live classes with expert teachers for real-time learning.",
    text_ar:
      "فصول دراسية مباشرة تفاعلية مع معلمين خبراء للتعلم في الوقت الحقيقي.",
    imageSrc: live,
  },
  {
    index: 5,
    title: "Up-to-Date Content",
    title_ar: "محتوى محدث",
    text: "Our content is regularly updated to match the latest curriculum.",
    text_ar: "يتم تحديث المحتوى بانتظام ليتوافق مع أحدث المناهج الدراسية.",
    imageSrc: uptodate,
  },
  {
    index: 6,
    title: "Progress Tracking",
    title_ar: "تتبع التقدم",
    text: "Track your child's learning progress with our advanced tracking tools.",
    text_ar: "تابع تقدم تعلم طفلك باستخدام أدوات التتبع المتقدمة.",
    imageSrc: progress,
  },
];
