import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import MyButton from "../MyButton";
import classes from "../../pages/page.module.css";
import settingsFormClasses from "./settingsForm.module.css";

export default function ChangeOtherSettingsForm() {


  return (
    <div className={settingsFormClasses.settings_form_container}>
      <p className={classes.titleText}>Change other settings?</p>
      <form className={settingsFormClasses.settings_form} onSubmit={()=>{}}>
        <div className={settingsFormClasses.settings_element}>
          ...
        </div>
      </form>
    </div>
  );
}




