import { useAuthenticationContext } from "../context/AuthenticationContext";
import { Navigate } from "react-router-dom";

function ProtectedUserRoute({ children }) {
  const { authenticationStatus } = useAuthenticationContext();

  if (
    authenticationStatus.authenticated
  ) {
    return children;
  }

  // Redirect to home page if not authenticated or not an admin
  return <Navigate to="/" replace />;
}

export default ProtectedUserRoute;